<template>
  <section>

    <b-skeleton-table
      v-if="spinner"
      :rows="paginationObject.limit"
      :columns="3"
      :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
    />
    <div
      v-else
      class="heading-elements"
    >
      <b-table
        id="venues-table"
        :items="allVenues"
        :per-page="paginationObject.limit"
        responsive
        :fields="tableColumns"
        show-empty
        hover
        selectable
        empty-text="No matching records found"
        class="float: right"
        @row-selected="onRowSelected($event, index)"
      >
        <!-- Column: Venue ID -->
        <template #cell(Id)="data">
          <span class="d-block text-nowrap devices">
            #{{ data.item.id }}
          </span>
        </template>
        <!-- Column: Venue Name -->
        <template #cell(Venue-Name)="data">
          <span>
            <p>{{ data.item.venue_name }}</p>
          </span>
        </template>
        <!-- Column: Venue Address -->
        <template #cell(Address)="data">
          <span>
            <p>{{ data.item.address }}</p>
          </span>
        </template>
      </b-table>
    </div>
    <div class="mx-2 mb-2">
      <CustomPagination
        :total="paginationObject.total"
        :page="paginationObject.offset"
        :per-page="paginationObject.limit"
        @perPageChanged="perPageChanged"
        @pageChanged="pageChanged"
      />
    </div>
  </section>
</template>
<script>
import {
  BTable,
  // BLink,
  BSkeletonTable,
  VBToggle,

} from 'bootstrap-vue'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import AccountTypes from '@/common/enums/accountTypeEnum'
import CustomPagination from '@/components/common/CustomPagination.vue'
import { showErrorMessage, showToast } from '@/common/global/functions'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    BTable,
    // BLink,
    BSkeletonTable,
    CustomPagination,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      spinner: false,
      tableColumns: [
        { key: 'Id', label: 'Venues' },
        { key: 'Venue-Name', label: 'Venue Name' },
        {
          key: 'Address',
          label: 'Venue Address',
        },
      ],
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
        searchQuery: '',
      },
      allVenues: [],
      AccountTypes,
    }
  },
  computed: {
    venue() {
      return this.$store.getters['venues/getVenue']
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  async created() {
    await this.getAllVenues(this.paginationObject)
  },
  methods: {
    onRowSelected(item) {
      if (this.userRole === this.AccountTypes.ADMIN) {
        this.$router.push({
          name: 'user-devices',
          params: {
            venueId: item[0].id,
            userId: this.$route.params.userId,
          },
        })
      } else {
        this.$router.push({
          name: 'devices',
          params: {
            venueId: item[0].id,
          },
        })
      }
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllVenues(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllVenues(this.paginationObject)
    },
    loadVenue() {
      this.getAllVenues(this.paginationObject)
    },
    async getAllVenues({ offset, limit, searchQuery }) {
      this.spinner = true
      const payload = {
        offset, limit, searchQuery, userHasGroupId: this.$route.params.userId,
      }
      try {
        const success = await this.$store.dispatch('venues/getAllVenue', { ...payload })

        if (success) {
          const { data } = this.venue
          this.allVenues = data.results
          this.paginationObject.total = data.total
          this.paginationObject.limit = data.limit
          showToast('Fetch Venues', 'Venues fetch successfully', 'success')
          this.spinner = false
        }
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Venues', 'You are not authorized for this action!', 'danger')
          this.spinner = false
        }
      }
    },
  },
}
</script>
<style lang="scss">
.all-devices{
  .group-badge{
    color: #000000;
    cursor: default;
    font-size: 12px;
    margin: 5px;
  }
  .device-delete-icon{
    font-weight: 400;
    color: #6e6b7b;
    margin-left: 3px;
    cursor: pointer;
    transition: all 0.5s;
    &:hover{
      color: blue;
    }
  }
  .card:last-of-type{
    margin-bottom: 10px;
  }
}

.vs__actions {
  font-weight: 100 !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.switchs {
  width: 50px;
  padding-inline: 40px;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  // background-color: #7FF0B2;
  color: white;
}
.devices{
  font-size: 14px;
  line-height: 11px;
  color: #1f58b5;
  font-weight: 500;
  font-family: "Montserrat";
}
.skeleton-loading-table{
  th, td{
    padding: 1rem 2rem !important;
    height: 3.1rem !important;
    .b-skeleton-text{
      height: 1.2rem;
      margin-bottom: 0;
    }
  }
}
@media (max-width: 575px) {
  .div-margin{
    margin-top: 50px;
  }

}
</style>
