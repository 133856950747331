<template>
  <b-container fluid>
    <Teleport to="#header-buttons">
      <div>
        <b-button
          id="show-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="openVenueModal"
        >
          Add Venue
        </b-button>
        <b-button
          v-if="!userRole===AccountTypes.ADMIN"
          id="device-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2"
          variant="primary"
          @click="gotoCreateDevice"
        >
          Add Screen
        </b-button>
      </div>
    </Teleport>
    <b-row>
      <b-col
        cols="12"
        class="px-0"
      >
        <b-card
          no-body
          class="mt-lg-0 mt-2"
          title="All Venues"
        >
          <AllVenues
            ref="allVenues"
          />
        </b-card>
      </b-col>
    </b-row>
    <AddVenue
      ref="add-venue"
      @reloadVenues="$refs.allVenues.loadVenue()"
    />
  </b-container>
</template>
<script>

import {
  BContainer, BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import Teleport from 'vue2-teleport'
import AllVenues from '@/components/venues/AllVenues.vue'
import AddVenue from '@/components/venues/AddVenue.vue'
import AccountTypes from '@/common/enums/accountTypeEnum'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    AllVenues,
    AddVenue,
    Teleport,
  },
  data() {
    return {
      AccountTypes,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
  },
  methods: {
    openVenueModal() {
      this.$refs['add-venue'].show()
    },
    gotoCreateDevice() {
      this.$router.push({
        name: 'create-device',
      })
    },
  },
}
</script>
